import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Carrier } from 'src/app/shared/models/classes/carrier';
import { PermanentTag } from 'src/app/shared/models/classes/misc';

export const StaticActions = createActionGroup({
  source: 'Static',
  events: {
    'Load Static Carriers': emptyProps(),
    'Load Static Carriers Success': props<{ carriers: Carrier[] }>(),
    'Load Static Carriers Failure': props<{ error: Error | null }>(),
    'Load Static Tags': props<{ agencyId: string }>(),
    'Load Static Tags Success': props<{
      agencyId: string;
      tags: PermanentTag[];
    }>(),
    'Load Static Tags Failure': props<{ error: Error | null }>(),
    'Update Static Tag': props<{ agencyId: string; tag: PermanentTag }>(),
    'Update Static Tag Success': props<{
      agencyId: string;
      tag: PermanentTag;
    }>(),
    'Update Static Tag Failure': props<{ error: Error | null }>(),
    'Add Static Tag': props<{ agencyId: string; tag: PermanentTag }>(),
    'Add Static Tag Success': props<{
      agencyId: string;
      tag: PermanentTag;
    }>(),
    'Add Static Tag Failure': props<{ error: Error | null }>(),
  },
});
